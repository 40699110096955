import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService {
  storage: Storage;

  constructor() {
    this.storage = window.sessionStorage;
  }

  get(key: string): any {
    if (this.isSessionStorageSupported) {
      const stored = this.storage.getItem(key);
      return stored ? JSON.parse(stored) : {};
    }

    return null;
  }

  set(key: string, value: any): boolean {
    if (this.isSessionStorageSupported) {
      this.storage.setItem(key, JSON.stringify(value));

      return true;
    }

    return false;
  }

  remove(key: string): boolean {
    if (this.isSessionStorageSupported) {
      this.storage.removeItem(key);

      return true;
    }

    return false;
  }

  get isSessionStorageSupported(): boolean {
    return !!this.storage;
  }
}

import { NgModule, Optional, SkipSelf, ModuleWithProviders, ErrorHandler } from '@angular/core';
import { interceptorProviders } from './http';
import { HttpClientModule } from '@angular/common/http';
import { CacheService } from './cache/cache.service';
import { StoreModule } from '@ngrx/store';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';
import { environment } from '@env/environment';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { authReducer } from './auth/store/auth.reducer';
import { AdminGuard,
         AuthEffects,
         AuthGuard,
         AuthService,
         ClientAccessGuard,
         LoggedInGuard,
         DocumentsSignedGuard
         } from './auth';
import { CommonModule } from '@angular/common';
import { AnimationsService } from './animations/animations.service';
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { DownloaderService } from './downloader/downloader.service';
import { AppInsightsService } from './analytics/app-insights.service';
import { CustomErrorHandler } from './errorHandler/errorHandler';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    StoreModule.forRoot({
      router: routerReducer,
      auth: authReducer,
    }),
    StoreRouterConnectingModule.forRoot(),
    EffectsModule.forRoot([AuthEffects]),
    MatSnackBarModule,
    environment.production
      ? []
      : StoreDevtoolsModule.instrument({
          maxAge: 30,
          name: 'Maas',
        }),
  ],
  providers: [
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 2500 } },
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreServicesModule');
  }
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        CacheService,
        AppInsightsService,
        interceptorProviders,
        AnimationsService,
        AuthService,
        AdminGuard,
        AuthGuard,
        DocumentsSignedGuard,
        LoggedInGuard,
        DownloaderService,
        ClientAccessGuard,
        {provide: ErrorHandler, useClass: CustomErrorHandler },
      ],
    };
  }
}

function throwIfAlreadyLoaded(parentModule: any, moduleName: string): void {
  if (parentModule) {
    throw new Error(`${moduleName} has already been loaded. Import Core modules in the AppModule only.`);
  }
}

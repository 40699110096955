import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, CanLoad, UrlTree, Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { concatMap, catchError} from "rxjs/operators";
import { Observable, of } from "rxjs";
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { AuthService } from "../services/auth.service";
import { Store } from "@ngrx/store";
import { AuthState } from "../store/auth.state";
import { map } from "rxjs";
import { tap } from "rxjs";
import { withLatestFrom } from "rxjs";
import { selectAuthState, selectIsAuthenticated, selectLoggedInUser } from "../store/auth.selectors";
import { switchMap } from "rxjs";

/**
 * Customized version of MsalGuard
 */
@Injectable()
export class LoggedInGuard implements CanActivate, CanActivateChild, CanLoad {

    constructor(
        private msalBroadcastService: MsalBroadcastService,
        private msalService: MsalService,
        private router: Router,
        private auth: AuthService,
        private store: Store<AuthState>
    ) {
        // Subscribing so events in MsalGuard will set inProgress$ observable
        this.msalBroadcastService.inProgress$.subscribe();
    }

    /**
     * Helper which checks for the correct interaction type, prevents page with Guard to be set as reidrect, and calls handleRedirectObservable
     * @param state
     */
    private activateHelper(state?: RouterStateSnapshot): Observable<boolean|UrlTree> {

        return this.msalService.handleRedirectObservable()
            .pipe(
                withLatestFrom(this.store.select(selectIsAuthenticated)),
                concatMap(([_, authenticated]) => {
                    // fully loaded
                    if (authenticated) return of(true);

                    if (!this.msalService.instance.getAllAccounts().length) {
                        if (state) {
                            this.msalService.getLogger().verbose("Guard - no accounts retrieved, log in required to activate");
                            return this.auth.login().pipe(map(() => true));
                        }

                        this.msalService.getLogger().verbose("Guard - no accounts retrieved, no state, cannot load");
                        return of(false);
                    }

                    // authenticated by MSAL, but doesn't have profile loaded
                    return this.auth.getProfile().pipe(
                        map(_ => true),
                        catchError(error => {
                            console.log(error);
                            return of(false);
                        })
                    );

                }),
                catchError((error: Error) => {
                    this.msalService.getLogger().error("Guard - error while logging in, unable to activate");
                    this.msalService.getLogger().errorPii(`Guard - error: ${error.message}`);
                    return of(this.router.parseUrl('/notice/adf'));
                })
            );
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean|UrlTree> {
        return this.activateHelper(state);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean|UrlTree> {
        return this.activateHelper(state);
    }

    canLoad(): Observable<boolean> {
        // @ts-ignore
        return this.activateHelper();
    }
}
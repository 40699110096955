import { Injectable } from '@angular/core';
import { map, filter } from 'rxjs/operators';
import { CanActivate, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { RootState } from '../../root-store';
import { Store } from '@ngrx/store';
import { selectCurrentClient } from '../store/auth.selectors';
import { ICurrentClient, SubscriptionOptions } from '../../models';
import { isNotNull } from '../../typeguards';

@Injectable({
  providedIn: 'root',
})
export class InsightsAccessGuard implements CanActivate, CanActivateChild {
  constructor(private store: Store<RootState>) {}

  canActivate(): Observable<boolean> {
    return this.store.select(selectCurrentClient).pipe(
      filter(isNotNull),
      map((client: ICurrentClient) => (client.subscriptions & SubscriptionOptions.EmployerInsights) == SubscriptionOptions.EmployerInsights),
    );
  }

  canActivateChild() {
    return this.canActivate();
  }
}

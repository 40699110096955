export const SERVICES = [
  'Banking',
  'Business Travel',
  'Cost-of-Living Consulting',
  'Cultural Training',
  'Destination Service Provider (DSP)',
  'Equity Services',
  'Health Insurance',
  'Household Goods Mover',
  'Immigration',
  'Language Training',
  'Payroll Compliance Services',
  'Professional Employer Organization (PEO)',
  'Relocation Management Company (RMC)',
  'Security and Risk Services',
  'Tax Services',
  'Technology Solutions',
  'Temporary Living',
];

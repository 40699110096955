import {
  trigger,
  style,
  animate,
  transition,
  query,
  stagger
} from '@angular/animations';

export const componentAnimations = trigger('categoryAnimation', [
  transition(':enter', [
      style({ opacity: 0,  }),
      query(':enter', [
        style({ opacity: 0,  transform: 'translateY(-30%)' }),
        stagger('25ms', [
          animate('0.5s ease-in-out', style({ opacity: 0, transform: 'translateY(0%)'}))
        ])
      ]
      ),
  ]),
  transition(':leave',
  [
    animate('0.5s ease-in-out',
    style({ opacity: 0, transform: 'translateY(-30%)'}))
  ]
  ),
]);
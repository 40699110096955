import { Injectable } from '@angular/core';
import { withLatestFrom, switchMap, map } from 'rxjs/operators';
import { CanActivate, Router, CanActivateChild, ActivatedRouteSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { RootState } from '../../root-store';
import { Store } from '@ngrx/store';
import { selectCurrentClient, selectLoggedInUser } from '../store/auth.selectors';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class ClientAccessGuard implements CanActivate, CanActivateChild {
  constructor(private store: Store<RootState>, private router: Router, private auth: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const clientId = route.paramMap.get('clientId');
    if (!clientId) return of(false);

    return this.store.select(selectLoggedInUser).pipe(
      withLatestFrom(this.store.select(selectCurrentClient)),
      switchMap(([user, client]) => {
        // if already loaded then no need for further checks
        if (client?.id === clientId) return of(true);

        // verify user is authorized to access client
        if (!user) return of(false);
        const authorized = user.clients.some((c) => c.id === clientId) || user.roles.some((r) => r === 'admin');
        if (!authorized) return of(this.router.parseUrl('/notice/not-in-app'));

        //verify account is active
        const isActive = user.clients.find((c)=>c.id== clientId && c.isActive== true);
        if (!isActive) return of(this.router.parseUrl('/app/account-disabled'));

        // authorized, now set current client
        return this.auth.setCurrentClient(clientId).pipe(
          map(() => true)
        );
      })
    );
  }

  canActivateChild(route: ActivatedRouteSnapshot) {
    return this.canActivate(route);
  }
}

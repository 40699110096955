/**
 * (c) 2020 KPMG LLP, a Delaware limited liability partnership
 * and the U.S. member firm of the KPMG network of independent member
 * firms affiliated with KPMG International Cooperative ("KPMG
 * International"), a Swiss entity. All rights reserved.
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PageTitleComponent } from './components/page-title/page-title.component';
import { GoBackComponent } from './components/go-back/go-back.component';
import { RouterModule } from '@angular/router';
import { A11yModule } from '@angular/cdk/a11y';
import { MaterialModule } from './material.module';

@NgModule({
  declarations: [
    PageTitleComponent,
    GoBackComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    A11yModule,
  ],
  exports: [
    FlexLayoutModule,
    ReactiveFormsModule,
    PageTitleComponent,
    GoBackComponent,
    A11yModule,
    MaterialModule,
    FormsModule,
  ]
})
export class SharedModule {}

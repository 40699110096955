import { Injectable } from '@angular/core';
import { CanActivate, Router, CanActivateChild } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { RootState } from '../../root-store';
import { Observable, map, tap } from 'rxjs';
import { selectDocumentsSigned } from '../store/auth.selectors';

@Injectable()
export class DocumentsSignedGuard implements CanActivate, CanActivateChild {

  constructor(private store: Store<RootState>, private router: Router) {}

  canActivate(): Observable<boolean> {
    return this.store.pipe(
      select(selectDocumentsSigned),
      map(signed => !!signed),
      tap(signed => {
        if (!signed) this.router.navigateByUrl('app/acknowledge');
      })
    );
  }

  canActivateChild() {
    return this.canActivate();
  }
}

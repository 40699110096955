/**
 * (c) 2019 KPMG LLP, a Delaware limited liability partnership
 * and the U.S. member firm of the KPMG network of independent member
 * firms affiliated with KPMG International Cooperative ("KPMG
 * International"), a Swiss entity. All rights reserved.
 */

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { catchError, concatMap, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import * as AuthActions from './auth.actions';
import { AuthService } from '../services/auth.service';
import { of } from 'rxjs';
import { CacheService } from '../../cache/cache.service';
import { selectAuthState } from './auth.selectors';
import { Store } from '@ngrx/store';
import { RootState } from '../../root-store';
import { LoggedInUser } from '../models';

@Injectable()
export class AuthEffects {

  loginAad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loginAad),
      switchMap(() =>
        this.auth.login().pipe(
          map(() => AuthActions.loginAadSuccess()),
          catchError((error) => of(AuthActions.loginAadFailed({ error })))
        )
      )
    )
  );

  inActiveClient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.sessionExpired),
      switchMap(() =>
      this.auth.logoutIdleUser()
      .pipe(
          mergeMap(() => this.router.navigate(['notice/expired'])))
      )
    ),
    { dispatch: false }
  );

  loginAadOnboarding$ = createEffect(() =>
  this.actions$.pipe(
    ofType(AuthActions.loginAadOnboarding),
    switchMap(() =>
      this.auth.login().pipe(
        map(() => AuthActions.loginAadSuccess()),
        catchError((error) => of(AuthActions.loginAadFailed({ error })))
      )
    )
  )
);

loginAadSuccess$ = createEffect(() =>
  this.actions$.pipe(
    ofType(AuthActions.loginAadSuccess),
    mergeMap(() => this.getUserProfile(true))
  )
);

loginAadFailure$ = createEffect(
  () =>
    this.actions$.pipe(
      ofType(AuthActions.loginAadFailed, AuthActions.checkAadAuthFailed, AuthActions.msalGuardSignInFailed),
      tap(() => this.router.navigate(['notice/adf']))
    ),
  { dispatch: false }
);

loadUserProfile$ = createEffect(() =>
  this.actions$.pipe(
    ofType(AuthActions.loadUserProfile),
    concatMap(() => this.getUserProfile(false))
  )
);

userHasNoClients$ = createEffect(() =>
  this.actions$.pipe(
    ofType(AuthActions.userHasNoClient),
    tap(() => this.router.navigateByUrl('notice/noemp1')),
  ),
  { dispatch: false }
);

  changeClient$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.changeClient),
        tap(({ client }) => {
          this.updateCachedClientId(client.id);
          this.redirectToCorrectHomeScreen(client.id, client.subscriptions);
        })
      ),
    { dispatch: false }
  );

  loadUserProfileFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.loadUserProfileFailed),
        tap(() => this.router.navigate(['notice/no-access']))
      ),
    { dispatch: false }
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.logout),
      switchMap(() => this.auth.logout())
    ),
    { dispatch: false }
  );

  updateUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.updateUser),
        withLatestFrom(this.store.select(selectAuthState)),
        tap(([_, state]) => {
          // since AuthActions.updateUser can sometimes change the currently selected client,
          // update the current client in the cache.
          if (state.currentClient) this.updateCachedClientId(state.currentClient.id);
        })
      ),
    { dispatch: false }
  );

  loadDocumentsToSign$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loadDocumentsToSign),
      switchMap(() =>
        this.auth.getDocumentsToSign().pipe(
          map((unsignedDocuments) => AuthActions.loadDocumentsToSignSuccess({ unsignedDocuments })),
          catchError((error) => of(AuthActions.loadDocumentsToSignFailed({ error })))
        )
      )
    )
  );

  signDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.signDocuments),
      switchMap(payload =>
        this.auth.signDocuments(payload.documentIds).pipe(
          map(() => AuthActions.signDocumentsSuccess()),
          catchError((error) => of(AuthActions.signDocumentsFailed({ error })))
        )
      )
    )
  );

signDocumentsSuccess$ = createEffect(() =>
  this.actions$.pipe(
    ofType(AuthActions.signDocumentsSuccess),
    withLatestFrom(this.store.select(selectAuthState)),
    tap(([_, state]) => {
      if (!state.user) return;
      const clientId = this.getClientId(state.user);
      if (!clientId) return;
      const subscription = state.user.clients.find((c) => c.id === clientId)?.subscriptions;
      if (subscription) this.redirectToCorrectHomeScreen(clientId , subscription)
    })
  ),
  { dispatch: false }
);

// createBillingSubscription$ = createEffect(() =>
//     this.actions$.pipe(
//       ofType(createBillingSubscription),
//       withLatestFrom(this.store.select(selectRouteParams)),
//       concatMap(([{ billingSubscription }, { clientId }]) =>
//         this.auth.createSubscription({
//           ...billingSubscription,
//           clientId
//         })
//         .pipe(
//           map(() => {
//             this.router.navigateByUrl(`/app/${clientId}/supplier-home`);
//             return createBillingSubscriptionSuccess();
//           }),
//           catchError((err) => of(createBillingSubscriptionFailure(err)))
//         ),
//       ),
//     ),
//   );

//   updateBillingInfo$ = createEffect(() =>
//     this.actions$.pipe(
//       ofType(updateBillingInfo),
//       switchMap(({ billingInfo }) =>
//         this.auth.createBillingInfo(billingInfo)
//       ),
//       map(() => updateBillingInfoSuccess()),
//       catchError((err) => of(updateBillingInfoFailure(err)))
//     ),
//   );

//   loadBillingPlan$ = createEffect(() =>
//   this.actions$.pipe(
//     ofType(loadBillingPlan),
//     switchMap(()  =>
//       this.auth.getBillingPlan().pipe(
//         map((billingPlan) => loadBillingPlanSuccess({ billingPlan })),
//         catchError((err) => of(loadBillingPlanFailure(err)))
//       )
//     )
//   )
// )

  constructor(
    private actions$: Actions,
    private router: Router,
    private auth: AuthService,
    private cache: CacheService,
    private store: Store<RootState>,
  ) { }

  private updateCachedClientId(clientId: string) {
    if (clientId) {
      this.cache.set('currentClientId', clientId);
    }
  }

  private getUserProfile(redirect: boolean) {
    return this.auth.getProfile().pipe(
      tap((user) => {

        if (user.clients.length === 0) {
          this.router.navigateByUrl('notice/noemp2');
          return;
        };

        if (!user.documentsSigned) {
          this.router.navigateByUrl('app/acknowledge');
          return;
        };

        const clientId = this.getClientId(user);
        const subscription = user.clients.find((c) => c.id === clientId)?.subscriptions;

        if (redirect && subscription) {
          this.redirectToCorrectHomeScreen(clientId, subscription);
        }
      }),
      map((user) => AuthActions.loadUserProfileSuccess(user)),
      catchError((error) => of(AuthActions.loadUserProfileFailed({ error })))
    );
  }

  private getClientId(user: LoggedInUser): string {
    const previousClientId: string = this.cache.get('currentClientId');
    if (user.clients.some((c) => c.id === previousClientId))
      return previousClientId;

    const clientId = user.clients[0].id;
    this.updateCachedClientId(clientId);

    return clientId;
  }

  private redirectToCorrectHomeScreen(clientId: string, subscription: number) {
    const redirectUrl = `app/${clientId}/${this.auth.getClientRedirectUrl(subscription)}`;
    this.router.navigateByUrl(redirectUrl);
  }

}

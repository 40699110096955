/**
 * (c) 2019 KPMG LLP, a Delaware limited liability partnership
 * and the U.S. member firm of the KPMG network of independent member
 * firms affiliated with KPMG International Cooperative ("KPMG
 * International"), a Swiss entity. All rights reserved.
 */

import { createReducer, on } from '@ngrx/store';
import { AuthState } from './auth.state';
import { AuthActions } from '.';
import { LoggedInUser } from '@core';

export const featureKey = 'auth';

const authInitialState: AuthState = {
  user: null,
  authenticated: false,
  authorized: null,
  currentClient: null,
  unsignedDocuments: null,
};

export const authReducer = createReducer(
  authInitialState,

  on(AuthActions.loadUserProfileSuccess, (state, user) => ({
    ...state,
    authenticated: true,
    authorized: true,
    user,
  })),

  on(AuthActions.loadDocumentsToSignSuccess, (state, { unsignedDocuments }) => ({
    ...state,
    unsignedDocuments,
  })),

  on(AuthActions.signDocumentsSuccess, (state) => ({
    ...state,
    user: {...state.user as LoggedInUser, documentsSigned: true},
  })),

  on(AuthActions.currentClientLoadSuccess, (state, { client }) => ({
    ...state,
    currentClient: client
  })),

  on(AuthActions.loadUserProfileFailed, (state, { error }) => ({
    ...state,
    currentClient: null,
    authenticated: false,
    authorized: false,
  })),

  on(AuthActions.logout, (state) => ({
    ...state,
    user: null,
    authenticated: false,
    authorized: false,
  })),


  on(AuthActions.sessionExpired, (state) => ({
    ...state,
    user: null,
    authenticated: false,
    authorized: false,
  })),

  on(AuthActions.updateUser, (state, { user }) => ({
    ...state,
    user,
  }))

);

import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Injectable } from '@angular/core';


@Injectable({providedIn: 'root'})
export class IconsService {
  constructor(private registry: MatIconRegistry, private sanitizer: DomSanitizer) { }

  initializeServiceTypeIcons(): void {

    /*
      workaround to remove manual entry for every new svg
        - when you add a svg to the codebase follow the steps
        - add to assets
        - add filename to arraylist
    */

    const serviceTypeIcons: string[] = [
      'account_balance',
      'badge',
      'balance',
      'backpack',
      'banking',
      'business',
      'close',
      'corporate_fare',
      'check_circle_filled',
      'emoji_objects',
      'family_restroom',
      'health_and_safety',
      'home',
      'local_shipping',
      'translate',
      'info',
      'paid',
      'savings',
      'security',
      'travel_explore',
      'flight_land',
      'request_quote',
      'eye',
      'add',
      'filter',
    ];

    serviceTypeIcons.forEach((icon) => {
      this.registry.addSvgIcon(
        icon,
        this.sanitizer.bypassSecurityTrustResourceUrl(`assets/images/${icon}_24dp.svg`)
      );
    })

  }

  init() {
    this.registry.addSvgIcon(
      'menu',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/menu.svg')
    );

    this.registry.addSvgIcon(
      'drag_handle',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/drag_handle_black_24dp.svg')
    );

    this.registry.addSvgIcon(
      'filter_white',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/filter_list_white_24dp.svg')
    );

    this.registry.addSvgIcon(
      'file_download',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/file_download_black_24dp.svg')
    );

    this.registry.addSvgIcon(
      'back_ios',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/arrow_back_ios_blue_24dp.svg')
    );

    this.registry.addSvgIcon(
      'back',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/arrow_back_black_24dp.svg')
    );

    this.registry.addSvgIcon(
      'forward_ios',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/arrow_forward_ios_FILL0_wght400_GRAD0_opsz40.svg')
    );

    this.registry.addSvgIcon(
      'clear-filter',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/highlight_off_black_18dp.svg')
    );

    this.registry.addSvgIcon(
      'download',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/file_download_black_24dp.svg')
    );

    this.registry.addSvgIcon(
      'add',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/add_24dp.svg')
    );
    this.registry.addSvgIcon(
      'capability',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/support_agent_FILL0_wght400_GRAD0_opsz48.svg')
    );
    this.registry.addSvgIcon(
      'value',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/price_change_FILL0_wght400_GRAD0_opsz48.svg')
    );
    this.registry.addSvgIcon(
      'quality',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/school_FILL0_wght400_GRAD0_opsz48.svg')
    );
    this.registry.addSvgIcon(
      'technology',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/system_update_FILL0_wght400_GRAD0_opsz48.svg')
    );
    this.registry.addSvgIcon(
      'delivery',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/contact_phone_FILL0_wght400_GRAD0_opsz48.svg')
    );
    this.registry.addSvgIcon(
      'data-security',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/network_locked_FILL0_wght400_GRAD0_opsz48.svg')
    );
    this.registry.addSvgIcon(
      'website',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/website.svg')
    );
    this.registry.addSvgIcon(
      'linkedin',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/linkedin-in-brands.svg')
    );
    this.registry.addSvgIcon(
      'done',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/done_FILL0_wght700_GRAD0_opsz24.svg')
    );
    this.registry.addSvgIcon(
      'exclamation',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/exclamation_FILL0_wght400_GRAD0_opsz24.svg')
    );

    this.registry.addSvgIcon(
      'survey_sent',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/send_black_24dp.svg')
    )
    this.registry.addSvgIcon(
      'survey_viewed',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/visibility_black_24dp.svg')
    )
    this.registry.addSvgIcon(
      'survey_started',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/edit_note_black_24dp.svg')
    )
    this.registry.addSvgIcon(
      'survey_completed',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/check_circle_black_24dp.svg')
    )

    this.initializeServiceTypeIcons();
  }
}

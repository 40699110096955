/**
 * (c) 2020 KPMG LLP, a Delaware limited liability partnership
 * and the U.S. member firm of the KPMG network of independent member
 * firms affiliated with KPMG International Cooperative ("KPMG
 * International"), a Swiss entity. All rights reserved.
 */

import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { NotLoggedInGuard, LoggedInGuard } from '@core';

const isIframe = window !== window.parent && !window.opener;

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'public/home',
  },

  {
    path: 'notice',
    loadChildren: () => import('./notice/notice.module').then(m => m.NoticeModule)
  },

  {
    path: 'public',
    canActivate: [NotLoggedInGuard],
    loadChildren: () => import('./public/public.module').then((m) => m.PublicModule),
  },
  {
    path: 'app',
    canActivate: [LoggedInGuard],
    loadChildren: () =>
      import('./application-wrapper/application-wrapper.module').then((m) => m.ApplicationWrapperModule),
  },

  {
    path: '**',
    redirectTo: 'public/home',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: 'always',
      // enableTracing: true,
      preloadingStrategy: PreloadAllModules,
      // relativeLinkResolution: 'legacy',
      // Don't perform initial navigation in iframes
      // Need to use 'enabledNonBlocking' because of our auth strategy:
      //    'enabled' defaults to 'enabledBlocking' which will prevent
      //    bootstrapping the application on a cold load.  Since we are
      //    triggering auth as part of the main app component, guards will
      //    never resolve on cold load using this strategy.  If there is a
      //    strong need to use enabledBlocking, we will need to reconsider
      //    the auth strategy.
      initialNavigation: !isIframe ? 'enabledNonBlocking' : 'disabled'
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { selectAuthState } from '../store/auth.selectors';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { RootState } from '../../root-store';

@Injectable({ providedIn: 'root' })
export class NotLoggedInGuard implements CanActivate {
  constructor(private store: Store<RootState>, private router: Router) {}

  canActivate(route?: ActivatedRouteSnapshot, state?: RouterStateSnapshot): Observable<boolean | UrlTree>  {
    return this.store.pipe(
      select(selectAuthState),
      map(s => {
        if (s.authenticated) {
          if (s.currentClient === null) {
            const defaultClientId = s.user?.clients[0]?.id;
            if (defaultClientId) {
              return this.router.parseUrl(`app/${defaultClientId}/home`)
            } else {
              return this.router.parseUrl('notice/noemp');
            }
          } else if (!s.authorized) {
            // allow navigation to proceed ... needed to allow authed but not authZ'd users to proceed to error pages
            return true;
          } else {
            // redirect to default client directory
            return this.router.parseUrl(`app/${s.currentClient.id}/home`);
          }
        } else {
          return true; // allow navigation to proceed
        }
      })
    );
  }
}

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first, mergeMap } from 'rxjs/operators';
import { selectAuthState } from '../auth/store/auth.selectors';
import { RootState } from '@core';

@Injectable({
  providedIn: 'root',
})
export class HeadersInterceptor implements HttpInterceptor {
  constructor(private store: Store<RootState>) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.store.select(selectAuthState).pipe(
      first(),
      mergeMap((authState) => {

        let clientId = req.headers.has('x-c')
          ? req.headers.get('x-c')
          : authState.currentClient !== null
            ? authState.currentClient.id
            : '';

        let newHeaders: { 'x-c'?: string} = {};
        if (clientId) newHeaders['x-c'] = clientId;
        const newRequest = req.clone({
          setHeaders: newHeaders,
        });;
        return next.handle(newRequest);
      })
    );
  }
}

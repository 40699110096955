import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { RootState } from '../../root-store';
import { Observable } from 'rxjs';
import { hasSuplierMarketplaceAccess } from '../store/auth.selectors';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class EmployerHomeGuard implements CanActivate {
  constructor(private store: Store<RootState>, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state?: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.store.pipe(
      select(hasSuplierMarketplaceAccess),
      map(can => can
        ? this.router.parseUrl(`app/${route.params.clientId}/supplier-home`)
        : true
      )
    );
  }
}

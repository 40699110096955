
export interface IClientInfo {
  id: string;
  name: string;
  subscriptions: SubscriptionOptions;
  activeSubscription?: boolean;
  isActive: boolean;
}

export interface ICurrentClient {
  id: string;
  name: string;
  profileSetup: boolean;
  subscriptions: SubscriptionOptions;
}

export enum SubscriptionOptions {
  None = 0,
  EmployerInsights = 1,
  EmployerMarketplace = 2,
  SupplierMarketplace = 4
}

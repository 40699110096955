import { Injectable, ErrorHandler } from '@angular/core';
import { AppInsightsService } from '../analytics/app-insights.service';

@Injectable()
export class CustomErrorHandler implements ErrorHandler {

  constructor(private insights: AppInsightsService) { }

  handleError(error: Error) {
    console.error(error);
    this.insights.logException(error);
  }
}
import { IDocument } from '@shared';
/**
 * (c) 2019 KPMG LLP, a Delaware limited liability partnership
 * and the U.S. member firm of the KPMG network of independent member
 * firms affiliated with KPMG International Cooperative ("KPMG
 * International"), a Swiss entity. All rights reserved.
 */

import { createAction, props } from '@ngrx/store';
import { LoggedInUser } from '../models/logged-in-user';
import { IClientInfo, ICurrentClient } from '../../models/client';

export const msalGuardSignInFailed = createAction(
  '[Auth Services] Msal Guard Activated - Login failed',
  props<{ error: any }>()
);

export const checkAadAuth = createAction('[App component] Check if user is logged in');
export const checkAadAuthIsLoggedIn = createAction('[Auth Effects] User is logged in');
export const checkAadAuthIsNotLoggedIn = createAction('[Auth Effects] User is not logged in');
export const checkAadAuthFailed = createAction(
  '[Auth Effects] Failed to check if user is logged',
  props<{ error: any }>()
);

export const loginAadSuccess = createAction('[Auth Effects] Azure AD login success');
export const loginAadFailed = createAction('[Auth Effects] Azure AD login failed', props<{ error: any }>());
export const loginAad = createAction('[Header component] User initiated login');
export const loginAadOnboarding = createAction('[Onboarding Status component] User initiated login');

export const sessionExpired = createAction('[Header component] User inactive intiated');

export const loadUserProfileSuccess = createAction(
  '[Auth Effects] Retrieved user profile',
  props<LoggedInUser>()
);

export const loadUserProfileFailed = createAction(
  '[Auth Effects] Failed to load current user profile',
  props<{ error: any }>()
);
export const loadUserProfile = createAction('[Auth Effects] Load current user profile');

export const userHasNoClient = createAction('[Auth Effects] User has no clients');

export const logout = createAction('[Header component] Logout');

export const setClient = createAction('[Client access guard] Change client', props<{ clientId: string }>());

export const changeClient = createAction('[Client picker] Change client', props<{ client: IClientInfo }>());
export const changeClientSuccess = createAction(
  '[Auth Effects] Change client success', props<({ client: ICurrentClient })>()
  );
export const changeClientFailed = createAction('[Auth Effects] Change client failed');

export const currentClientLoadSuccess = createAction(
  '[Auth Effects] Loaded current client', props<{ client: ICurrentClient }>()
  );
export const currentClientLoadFailed = createAction('[Auth Effects] Failed to load current client');

export const noClientsRedirect = createAction('[My Account Overview] User has no clients');

export const updateUser = createAction('[Admin Add User] Update User', props<{ user: LoggedInUser }>());

export const markProfileAsComplete = createAction(
  '[Employer Profile Effects] Mark profile as complete',
  props<{ clientId: string }>()
);

export const loadDocumentsToSign = createAction('[Acknowledge documents component] Load documents to sign');

export const loadDocumentsToSignSuccess = createAction(
  '[Auth Effects] Load documents to sign success', props<{unsignedDocuments: IDocument[]}>()
  );
export const loadDocumentsToSignFailed = createAction(
  '[Auth Effects] Load documents to sign failed', props<{ error: any }>()
  );

export const signDocuments = createAction('[Acknowledge documents component] Sign documents',
  props<{ documentIds: number[] }>()
);

export const signDocumentsSuccess = createAction('[Auth Effects] Sign documents success');
export const signDocumentsFailed = createAction('[Auth Effects] Sign documents failed',
  props<{ error: any }>()
);

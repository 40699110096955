/**
 * (c) 2017 KPMG LLP, a Delaware limited liability partnership
 * and the U.S. member firm of the KPMG network of independent member
 * firms affiliated with KPMG International Cooperative ("KPMG
 * International"), a Swiss entity. All rights reserved.
 */
import { LogLevel } from '@azure/msal-browser';

export const environment = {
  production: true,
  //recaptcha: '#{PLACEHOLDER_RECAPTCHA_ENVIRONMENT_KEY}',
  billing: '#{PLACEHOLDER_BILLING_ENVIRONMENT_KEY}',
  appName: 'Dot to Dot',
  apiUrl: '/api',
  toEmail: '#{PLACEHOLDER_TAXAI_SMTP_EMAIL_SUPPORT_INBOX}',
  helpUrl: 'google.com',
  emailSubject: 'Mobility Services Support',
  logLevel: LogLevel.Info,
  azure: {
    redirectUrl: '#{PLACEHOLDER_TAXAI_APP_URL}',
    tenant: '#{PLACEHOLDER_TAXAI_AUTH_TENANT_WEB}',
    // Requires a fake guid here in order to not throw a random ADAL error for it being blank (error doesn't really matter just clouds the console)
    clientId: '#{PLACEHOLDER_TAXAI_AUTH_CLIENT_ID}',
  },
  insightsConnectionString: '#{PLACEHOLDER_TAXAI_APPINSIGHTS_CONNECTION_STRING}',
  enableTracking: true,
};

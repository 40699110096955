import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

import { AppInsightsService } from '../analytics/app-insights.service';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private snackbar: MatSnackBar, private _insights: AppInsightsService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 400) {
          this.handleValidationErrors(err)
        } else if (err.status === 500 && err.headers.get('exceptiontype') === 'business') {
          this.handleBusinessError(err);
        } else {
          this.handleGenericError(err);
        }

        // allow error to propagate - required for ngRx to function
        return throwError(() => err);
      })
    );
  }

  private notifyUser(message: string) {
    this.snackbar.open(message, 'dismiss', { panelClass: 'exception-snackbar' });
  }

  private handleGenericError(err: HttpErrorResponse) {
    this._insights.logHttpError(err, SeverityLevel.Error);

    if (err.headers.get('content-type') === 'text/html') {
      this.notifyUser('an error seems to have occurred. please try again later');
    } else {
      this.notifyUser('an error seems to have occurred but our team is already on it! please try again later');
    }
  }

  private handleBusinessError(err: HttpErrorResponse) {
    this._insights.logHttpError(err, SeverityLevel.Information);

    let msg = '';
    err.error instanceof Blob
      ? err.error.text().then(text => msg = text)
      : msg = err.error;

    this.notifyUser(msg);
  }

  private handleValidationErrors(err: HttpErrorResponse) {
    this._insights.logHttpError(err, SeverityLevel.Information);

    type ValidationErrorRecord = {
      errorMessage: string;
    };

    const errors: string | ValidationErrorRecord[] = err.error;
    // add index plus 1 to display error messages in sequential order for friendly ux
    // join on white space so we can apply whitespace styling
    if (Array.isArray(errors)) {
      const messages = errors.map((e, i) => { return `${i + 1}: ${e?.errorMessage}` }).join('\n');
      this.notifyUser(messages);
    }
    if (typeof errors === 'string') {
      this.notifyUser(errors);
    }
  }
}

import { IDocument } from '@shared';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoggedInUser } from '../models/logged-in-user';
import { MsalService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { PopupRequest } from '@azure/msal-browser';
import { Store } from '@ngrx/store';
import { AuthActions } from '../store';
import { RootState } from '../../root-store';
import { tap } from 'rxjs/operators';
import { ICurrentClient, SubscriptionOptions } from '../../models';
import { exhaustMap } from 'rxjs';
import { IBillingInfo, IBillingPlanDTO, IBillingSubscription } from 'src/app/billing/models/billing.models';
import { AppInsightsService } from '../../analytics/app-insights.service';

@Injectable()
export class AuthService {
  constructor(
    private http: HttpClient,
    private msal: MsalService,
    private store: Store<RootState>,
    private appInsights: AppInsightsService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
  ) { }

  login() {
    return this.msal.loginPopup({...this.msalGuardConfig.authRequest} as PopupRequest)
      .pipe(
        exhaustMap(response => {
          this.msal.instance.setActiveAccount(response.account);
          if (response.account) this.appInsights.setLogin(response.account.username);
          return this.getProfile();
        }));
  }

  logoutIdleUser() {
    return this.msal.logout({
      onRedirectNavigate: () =>  false
      });
  }

  getDocumentsToSign() {
      return this.http.get<IDocument[]>('acknowledge/userDocuments');
  }

  signDocuments(userDocumentIds: number[]) {
    return this.http.post<boolean>('acknowledge/userDocuments', userDocumentIds);
  }

  getBillingPlan() {
    return this.http.get<IBillingPlanDTO>('billing/plan');
  }

  createBillingInfo(data: IBillingInfo) {
    return this.http.put('billing/billinginfo', data);
  }

  createSubscription(data: IBillingSubscription) {
    return this.http.post('billing/subscription', data);
  }

  getProfile() {
    return this.http.get<LoggedInUser>(`me`).pipe(
      tap(user => this.store.dispatch(AuthActions.loadUserProfileSuccess(user)))
    );
  }

  setCurrentClient(clientId: string) {
    // because client id is not set we have to manually add client header
    const headers = new HttpHeaders().set('x-c', clientId);
    return this.http.get<ICurrentClient>(`clients/${clientId}`, { headers }).pipe(
      tap(client => this.store.dispatch(AuthActions.currentClientLoadSuccess({ client })))
    );
  }

  logout() {
    return this.msal.logoutPopup({
      mainWindowRedirectUri: '/'
    });
  }

  getClientRedirectUrl(subscription: number): string {
    const isSupplier = !!(subscription & SubscriptionOptions.SupplierMarketplace);
    return isSupplier
      ? 'supplier-home'
      : 'home'
  }
}

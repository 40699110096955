import { Injectable } from '@angular/core';

@Injectable()
export class DownloaderService {

  downloadBlob(data: Blob | null, filename: string) {
    if (data)
      this.downloadLink(window.URL.createObjectURL(data), filename);
  }

  downloadLink(url: string, filename: string) {
    const link = document.createElement('a');
    link.href = url;

    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

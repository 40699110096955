import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { Auth } from '../store';
import { RootState } from '../../root-store';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private store: Store<RootState>, private router: Router) {}

  // Ensures a user has access to a route, if the user has at least one user type defined for that route.
  canActivate(route?: ActivatedRouteSnapshot, state?: RouterStateSnapshot): Observable<boolean> {
    return this.store.select(Auth.selectIsAuthenticated)
      .pipe(tap(ok => ok || this.router.parseUrl('/notice/not-in-app')));
  }

  canActivateChild() {
    return this.canActivate();
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { RootState } from '../../root-store';
import { Observable } from 'rxjs';
import { selectIsAdmin } from '../store/auth.selectors';

@Injectable()
export class AdminGuard implements CanActivate {
  constructor(private store: Store<RootState>, private router: Router) {}

  canActivate(route?: ActivatedRouteSnapshot, state?: RouterStateSnapshot): Observable<boolean> {
    return this.store.select(selectIsAdmin);
  }
}

import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ApplicationInsights, IAutoExceptionTelemetry, SeverityLevel } from '@microsoft/applicationinsights-web';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';
import { environment } from '@env/environment';
import { Router } from '@angular/router';

@Injectable()
export class AppInsightsService {

    private _angularPlugin: AngularPlugin | undefined;
    private _clickPlugin: ClickAnalyticsPlugin | undefined;
    private _appInsights: ApplicationInsights | undefined;

    constructor(private router: Router) {

        if (!environment.enableTracking) return;

        this._angularPlugin = new AngularPlugin();
        this._clickPlugin = new ClickAnalyticsPlugin();
        const appInsights = new ApplicationInsights({ config: {
            connectionString: environment.insightsConnectionString,
            autoTrackPageVisitTime: true,
            enableCorsCorrelation: true,
            enableRequestHeaderTracking: true,
            enableResponseHeaderTracking: true,
            extensions: [this._angularPlugin, this._clickPlugin],
            extensionConfig: {
                [this._angularPlugin.identifier]: {
                    router: this.router
                },
                [this._clickPlugin.identifier]: {
                    autoCapture: true,
                    dataTags : {
                        customDataPrefix : 'data-d2d-',
                        parentDataTag : 'parent-group',
                        donotTrackDataTag : 'dnt',
                        captureAllMetaDataContent : false,
                        useDefaultContentNameOrId : true
                    }
                }
            }

        }});

        appInsights.loadAppInsights();
        appInsights.addTelemetryInitializer((envelope) => {
            envelope.tags = envelope.tags || [];
            envelope.tags.push({ "ai.cloud.role": environment.appName });
          });

        this._appInsights = appInsights;
    }

    setLogin(login: string) {
        this._appInsights?.setAuthenticatedUserContext(login, undefined, true);
    }

    logException(exception: Error | IAutoExceptionTelemetry): void {
        this._appInsights?.trackException({ exception });
    }

    logHttpError(error: HttpErrorResponse, severityLevel?: SeverityLevel) {
        this._appInsights?.trackException({ exception: error, severityLevel });
    }
}

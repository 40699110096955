import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router } from '@angular/router';
import { map } from 'rxjs';
import { Store } from '@ngrx/store';

import { GuardTypes, RootState, Auth } from '@core';

@Injectable()
export class OnboardingPaymentSubscriptionGuard implements CanActivate, CanActivateChild {

  constructor(private store: Store<RootState>, private router: Router) {};

  canActivate(route?: ActivatedRouteSnapshot): GuardTypes {
    return this.store.select(Auth.selectIsActivePaymentSubscription).pipe(
      map((s) => s ? this.router.parseUrl(`/app/${route?.params?.clientId}/supplier-home`) : true)
    )
  }

  canActivateChild(route?: ActivatedRouteSnapshot) {
    return this.canActivate(route);
  }
}

/**
 * (c) 2019 KPMG LLP, a Delaware limited liability partnership
 * and the U.S. member firm of the KPMG network of independent member
 * firms affiliated with KPMG International Cooperative ("KPMG
 * International"), a Swiss entity. All rights reserved.
 */

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthState } from './auth.state';
import * as fromAuth from './auth.reducer';
import { RootState } from '../../root-store';
import { SubscriptionOptions } from '../../models';

export const selectAuthState = createFeatureSelector<AuthState>(fromAuth.featureKey);

const getIsAdmin = (state: RootState) => state.auth.user && state.auth.user.roles.indexOf('admin') !== -1;

export const selectLoggedInUser = createSelector(selectAuthState, (state) => state.user);

export const selectClients = createSelector(selectAuthState, (state) => state.user?.clients);

export const selectIsAdmin = createSelector(selectLoggedInUser, (user) => user?.roles.indexOf('admin') !== -1 ?? false);

export const selectDocumentsSigned = createSelector(selectLoggedInUser, (user) => user?.documentsSigned);

export const selectUnsignedDocuments = createSelector(selectAuthState, (state) => state?.unsignedDocuments);

export const selectIsAuthenticated = createSelector(selectAuthState, (state) => state.authenticated ?? false);

export const selectCurrentClient = createSelector(selectAuthState, (state) => state.currentClient);

export const selectIsActivePaymentSubscription = createSelector(
  selectLoggedInUser, selectCurrentClient,
  (user, currentClient) => {
    return user?.clients.reduce((acc, client) =>
      client?.activeSubscription && client.id === currentClient?.id ? true : acc, false);
  }
);

export const hasEmployerInsightsAccess = createSelector(selectCurrentClient, client =>
  !!client && (client.subscriptions & SubscriptionOptions.EmployerInsights) === SubscriptionOptions.EmployerInsights);


export const hasEmployerMarketplaceAccess = createSelector(selectCurrentClient, client =>
  !!client && (client.subscriptions & SubscriptionOptions.EmployerMarketplace) === SubscriptionOptions.EmployerMarketplace);


export const hasSuplierMarketplaceAccess = createSelector(selectCurrentClient, client =>
  !!client && (client.subscriptions & SubscriptionOptions.SupplierMarketplace) === SubscriptionOptions.SupplierMarketplace);

export const canAccessClient = (clientId: string) => createSelector(selectCurrentClient, getIsAdmin, (client, admin) => admin || client?.id === clientId);

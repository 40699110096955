/**
 * (c) 2020 KPMG LLP, a Delaware limited liability partnership
 * and the U.S. member firm of the KPMG network of independent member
 * firms affiliated with KPMG International Cooperative ("KPMG
 * International"), a Swiss entity. All rights reserved.
 */

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { IconsService } from '../@shared/services/icons.service';

@Component({
  selector: 'maas-app',
  templateUrl: './app.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [IconsService]
})
export class AppComponent implements OnInit {
  isIframe = window !== window.parent && !window.opener;

  constructor(private icons: IconsService) { }

  ngOnInit() {
    this.icons.init();
  }
}

import { of as observableOf, Observable } from 'rxjs';

import { tap } from 'rxjs/operators';
/**
 * (c) 2017 KPMG LLP, a Delaware limited liability partnership
 * and the U.S. member firm of the KPMG network of independent member
 * firms affiliated with KPMG International Cooperative ("KPMG
 * International"), a Swiss entity. All rights reserved.
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

/**
 * Provides caching capability
 *
 * @export
 */
@Injectable()
export class CacheService {
  constructor(private http: HttpClient) {}

  /**
   * Retrieves and caches results on any url
   *
   */
  getAndCache<T>(url: string): Observable<T> {
    const key = `service: ${url}`;
    const list = this.get<T>(key);

    if (list !== null) {
      return observableOf(list);
    }

    return this.http.get<T>(url).pipe(tap((data) => this.set(key, data)));
  }

  checkVersion(): Observable<string> {
    return this.http.get('Version', { responseType: 'text' });
  }

  /**
   * Check if the key is already cached
   *
   */
  public isExists(key: string): boolean {
    return this.get(key) !== null;
  }

  /**
   * Retrieve cached value
   *
   */
  public get<T>(key: string): T {
    const item = localStorage.getItem(key);
    return item && JSON.parse(item);
  }

  /**
   * Sets the cached value
   *
   */
  public set(key: string, value: any): void {
    if (value) {
      localStorage.setItem(key, JSON.stringify(value));
    }
  }

  /**
   * Removes cached value by a key
   *
   */
  public remove(...keys: Array<string>): void {
    keys.forEach((key: string) => localStorage.removeItem(key));
  }

  /**
   * Evicts all entries from cache
   *
   */
  public clear(): void {
    const keys: Array<string> = [];
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key) keys.push();
    }

    this.remove(...keys);
  }

  /**
   * Initialize service by checking if cache is stale
   *
   * @memberof CacheService
   */
  public init() {
    // const cacheVersion = this.get<string>('Version');
    // this.checkVersion().subscribe(currentVersion => {
    //     if (currentVersion && cacheVersion !== currentVersion) {
    //         this.clear();
    //         localStorage.clear();
    //         this.set('Version', currentVersion);
    //     }
    // })
  }
}

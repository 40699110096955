export const PUBLIC_ENDPOINTS = {
  onboardingContract: 'acknowledge/onboardingContract',
  companyDetails: 'contact/companydetails',
  supplierDetails: 'contact/supplierdetails',
  additionalDetails: 'contact/details',
  moreinfo: 'contact/moreinfo',
  loadPublicSearchQuestions: 'fit/fitpublicsearchquestions',
  shallowSearch: 'fit/shallowsearch',
  submitPublicFitSearch: 'fit/publicsearch',
  loadPublicFitResults: 'fit/fitpublicsearchresult',
  contactPublicSearchSuppliers: 'fit/contactpublicsearchsuppliers',
  downloadPublicSearchResponses: 'profiles/downloadpublicsearchresponses',
  checkSearchExpiry: 'fit/checksearchexpiry',
  updateEmail: 'fit/updateemail'
}
